import {translate} from 'sulu-admin-bundle/utils';
import {AbstractFormToolbarAction} from 'sulu-admin-bundle/views';
import {Requester} from 'sulu-admin-bundle/services';

export default class NewsletterGenerateAction extends AbstractFormToolbarAction {
  getToolbarItemConfig() {
    const formData = this.resourceFormStore.data;

    return {
      type: 'button',
      label: translate('app.newsletter_generate'),
      icon: 'su-copy',
      onClick: this.handleClick,
    };
  }

  handleClick = (id) => {
    Requester.post('/admin/api/newsletter/' + this.form.collaborationStore.id + '/generate')
    .then((res) => {
      const input = document.createElement('input');
      const text = res.html;
      input.setAttribute('value', text);
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      alert('Code HTML copié');
    })
    .catch(() => {
      alert('Une erreur est survenue');
    })
  };
}
