// Add project specific javascript code and import of additional bundles here:
import {formToolbarActionRegistry, listToolbarActionRegistry} from 'sulu-admin-bundle/views';
import NewsletterSendAction from "./formToolbarActions/NewsletterSendAction";
import NewsletterImportAction from "./formToolbarActions/NewsletterImportAction";
import NewsletterExportAction from "./formToolbarActions/NewsletterExportAction";
import NewsletterGenerateAction from "./formToolbarActions/NewsletterGenerateAction";

formToolbarActionRegistry.add('app.newsletter.send', NewsletterSendAction);
formToolbarActionRegistry.add('app.newsletter.import', NewsletterImportAction);
formToolbarActionRegistry.add('app.newsletter.export', NewsletterExportAction);
formToolbarActionRegistry.add('app.newsletter.generate', NewsletterGenerateAction);

//Start CKEditor
import {ckeditorPluginRegistry, ckeditorConfigRegistry} from 'sulu-admin-bundle/containers';

ckeditorConfigRegistry.add((config) => ({
  toolbar: [
    'heading',
    'bold', 'italic', 'underline', 'strikethrough',
    'subscript', 'superscript', 
    'bulletedlist', 'numberedlist',
    'internalLink', 'externalLink',
    'alignment'
  ],
}));
//End CKEditor
